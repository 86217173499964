import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldLorelei: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you build Lorelei?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_lorelei.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you build Lorelei?</h1>
          <h2>
            Not sure if building Lorelei is the right move? No worries! Check
            our review and guide for her!
          </h2>
          <p>
            Last updated: <strong>14/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Build? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="lorelei"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          As a free character, whose portrays will be provided to you
          eventually, and with her strong DMG and Crit buffs, along with being
          one of the best Star supports in general, there is very little reason
          not to build Lorelei for most Timekeepers. Her skills balance a mix of
          mostly offensive support with some damage reduction. She is extremely
          easy to use.
        </p>
        <SectionHeader title="Lore" />
        <p>
          Lorelei's singing voice made the legend of the mermaids on the Rhine
          River come true. Her voice is melodious, and she generously sings a
          song for anyone in need. Perhaps people know she is not a mythical
          being, but they are willing to call her one out of blessing and
          expectation. When she sings, her hymns can bring comfort to people,
          but they can also drive them to madness. Fortunately, Lorelei only
          wishes to use her voice to heal others, rather than lead them down a
          path that goes against the divine.
        </p>
        <p>
          Lorelei was a child raised with wholehearted love by her adoptive
          parents, from whom she inherited a steadfast and devout faith. Doing
          good deeds and loving your neighbor is her unquestionable way of life.
          Loving herself entirely is the lesson she has always strived to
          master. If love is impartial, then identity, age, and race should not
          be the rivers that separate people. If uncontrollable madness is an
          innate gift, she can only embrace it with an open arm and accept
          everything with love.
        </p>
        <SectionHeader title="Review" />
        <p>
          Lorelei is a 5-star character from the Star Afflatus who deals Mental
          damage and belongs to the Support class. You probably already noticed
          that she is free, and a very good one at that, unlike a certain free
          Star character from the past. Lorelei’s kit is overall very flexible,
          and she ideally fits into most Crit teams since her kit is quite
          self-contained. Her skill “Call of the Sea” is a mass buff that grants
          [Critical DMG] +25% and [DMG Taken Reduction] for 2 rounds. The crit
          buff values are quite high and can even reach up to 45%. “On The
          Shore” is a mass attack that inflicts [Weakness], which reduces the
          enemy's damage output.
        </p>
        <p>
          As you can see, all of these incantations are very easy to use. Since
          they are all mass buffs or attacks, you don’t have to select
          individual targets, and there’s no real setup required, making her
          quite user-friendly. Her Ultimate is a bit more complicated but still
          remains fairly simple. “A Drifting Song” is a mass attack that deals
          damage and also gains bonuses based on the types of incantations in
          the Spelldock. If you have Attack incantations, you deal additional
          Mental DMG; with Buff incantations, you gain Critical Rate; and with
          Debuff incantations, you gain Penetration Rate.
        </p>
        <p>
          While this might be a bit wordy, all the effects essentially just
          increase your damage, so there’s not much manual setup or thinking
          required unless you’re aiming to min-max. Her Insights are also very
          easy to use and integrate well into her kit. Insight I grants
          different effects at the start of the round, depending on the types of
          incantations in your Spellbook, much like her ultimate. All effects
          are Mass. They can range from granting an ally a DMG Buff, increasing
          their Critical Rate, or debuffing the enemy’s DMG Dealt. Insight III
          simply gives her +1 Moxie if she happens to crit while using her
          ultimate.
        </p>
        <p>
          I wanted to note that it's typically not hard to max out a 5-star
          character. However, it is worth mentioning that you will eventually
          get all copies of her as you progress through the game. The effects
          from her portraits are mostly percentage buffs, which is always nice
          to have.
        </p>
        <p>
          Lorelei is a very strong and easy-to-use support character who can fit
          into any Crit team. She doesn’t require much setup and should perform
          well simply by using her incantations at any interval.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    She’s free and strong, I’m not sure what else can be asked
                    of her. And her portrays will be unlocked eventually.
                  </li>
                  <li>
                    Her kit is very flexible and integrates well into most Crit
                    teams. A majority of her skills are quite explanatory and it
                    really just works without much setup.
                  </li>
                  <li>
                    Various buffs/debuffs including DMG Buffs, Crit Buffs and
                    DMG Reduction against enemies.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    While she is very strong and can benefit any team, sometimes
                    you don’t need that many Crit Buffs. Some people might
                    already be using Tooth Fairy and find that to be sufficient.
                    Alternatively, you might be using a setup that relies more
                    on other mechanics, such as [Eureka], which she doesn’t
                    specifically support.
                  </li>
                  <li>
                    While she is overall much better than Sonetto, some people
                    might say she lacks the flexibility that Sonetto offers.
                    Lorelei is best suited for Crit teams, whereas Sonetto has
                    greater versatility, albeit with less specialization and
                    strengths.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          Her portrays are all going to be given. For this event she’ll have it
          as a reward for completing and clearing some tasks. . More portrays
          will be coming over patches.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Any character who benefits from DMG and Crit Buffs will work
          excellently with her. This includes a wide range of characters, so
          feel free to pick whoever you prefer. Here are just a few examples.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="windsong"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="regulus"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lucy"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="j"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on her:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="his-bounden-duty"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="long-night-talk"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/lorelei_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/lorelei_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/lorelei_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/lorelei_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <h5>I hear of Echoes and Robotic Woman</h5>
        <div className="team-box">
          <h5 className="star">
            Double Vampire
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lorelei"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="tooth-fairy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>
                  Secondary Attacker, 37 (To utilize her extra action)
                </strong>
              </li>
              <li>
                Lucy is an upcoming character of the Mind Afflatus, so you don’t
                really need a mono team for most of what you’ll be doing with
                her, which is perfect for this situation. While this doesn’t
                fully utilize her compatibility with Eureka from her extra
                action, it’s still a very strong and simple team that functions
                very well. Every single one of Lorelei’s buffs will be useful
                for Lucy. Lorelei requires almost no thought to use. Tooth Fairy
                is an easy and the best healer slot-in for most Crit teams. She
                lowers the enemies' Crit Resist, which can be stacked and does
                not interfere with Lorelei’s buffs.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Missing Tooth<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lorelei"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="yenisei"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Mercuria,6</strong>
              </li>
              <li>
                I wanted to show a setup that doesn’t need Tooth Fairy, though I
                still recommend using her for the most part. Yenisei is just
                here to keep your team alive; she doesn’t provide any particular
                buffs specific to Crits. However, she does her job very well,
                and as a 5-star character, she’s not super hard to get. Windsong
                is an extremely powerful character, and all of Lorelei’s effects
                are beneficial for Windsong. You’ve probably already seen our
                review for her, but the strategy is really just to use her
                “Rehabilitation” skill and then her ultimate, and it just works.
                Lorelei will provide your typical buff supports to make Windsong
                hit even harder.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            We’re all free?? <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="eagle"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lorelei"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="la-source"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>Survival Options such as Dikke</strong>
              </li>
              <li>
                While this team may not be the most optimal, it should still
                work for most content, and everyone here is free, so you likely
                already have them. This team might be a bit challenging to keep
                alive, but realistically, you should have at least some non-free
                units. This example is extreme, just to show that practically
                any setup can work with her if you configure its decently. You
                can also go with Matilda instead of Eagle.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldLorelei;

export const Head: React.FC = () => (
  <Seo
    title="Should you build Lorelei? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if building Lorelei is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
